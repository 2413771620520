<template>
    <div id="SalesPlanning">
        <v-container fluid app>
            <ZnapHotGrid
                :options="options"
            >
            </ZnapHotGrid>
        </v-container>
    </div>
</template>

<script>
import Vue from 'vue'
import ZnapHotGrid from '@/components/znap/HotGridCustomer.vue'

export default {
    name:'SalesPlanning',

    components:{
        ZnapHotGrid
    },

    data() {
        return {
            options: [
                {
                    column: 'id_event',
                    is: 'ComboBox',
                    items: [],
                    multiple: false,
                    label: 'Evento *',
                    required: true,
                    filterable: false,
                    endpoint: [ Vue.prototype.$ipEvent, 'event' ],
                    conditions: [
                        // {
                        //     AndOr: "AND",
                        //     column: "id_module",
                        //     operator: "=",
                        //     value: 5, // fixo
                        // },
                        // {
                        //     AndOr: "AND",
                        //     column: "id_status",
                        //     operator: "NOT LIKE",
                        //     value: 2, // fixo
                        // },
                        // {
                        //     AndOr: "OR",
                        //     column: "id_planning_function",
                        //     operator: "=",
                        //     value: 14, // fixo
                        // }
                    ]
                },
                {
                    column: 'id_planning_group',
                    is: 'ComboBox',
                    items: [],
                    multiple: false,
                    label: 'Grupo de planejamento',
                    required: true,
                    filterable: true,
                    endpoint: [ Vue.prototype.$ipApprovalFlow, 'planning-group' ],
                    conditions: []
                },
                {
                    column: 'id_sales_channel',
                    is: 'ComboBox',
                    items: [],
                    multiple: false,
                    label: 'Canal de vendas',
                    required: false,
                    filterable: true,
                    endpoint: [ Vue.prototype.$ipSales, 'sales-channel', 'list-options-planning' ]
                },
                {
                    column: 'id_sales_force',
                    is: 'ComboBox',
                    items: [],
                    multiple: false,
                    label: 'Gerência executiva',
                    required: false,
                    filterable: true,
                    endpoint: [ Vue.prototype.$ipSales, 'sales-force', 'list-options-planning' ],
                    conditions: [
                        {
                            AndOr: 'AND',
                            column: 'id_sales_force_type',
                            operator: '=',
                            value: 6
                        }
                    ]
                },
                {
                    column: 'id_sales_force_bp',
                    is: 'ComboBox',
                    items: [],
                    multiple: false,
                    label: 'BP',
                    required: false,
                    filterable: true,
                    endpoint: [ Vue.prototype.$ipSales, 'sales-force', 'list-options-planning' ],
                    conditions: [
                        {
                            AndOr: 'AND',
                            column: 'id_sales_force_type',
                            operator: '=',
                            value: 7,
                        }
                    ]
                },
                {
                    column: 'id_product',
                    is: 'ComboBox',
                    items: [],
                    multiple: false,
                    label: 'Produto',
                    required: true,
                    filterable: true,
                    endpoint: [ Vue.prototype.$ipProduct, 'product-sales-planning' ],
                    conditions: [
                        // {
                        //     AndOr: "AND",
                        //     column: "fiscal_year",
                        //     operator: "=",
                        //     value: null, // pegar do evento
                        // },
                        // {
                        //     AndOr: "AND",
                        //     column: "id_event_type",
                        //     operator: "=",
                        //     value: null, // pegar do evento
                        // },
                        // {
                        //     AndOr: "AND",
                        //     column: "planning_by_product",
                        //     operator: "=",
                        //     value: 1, // fixo
                        // }
                    ]
                },
                {
                    column: 'id_customer_group',
                    is: 'ComboBox',
                    items: [],
                    multiple: false,
                    label: 'Conta cliente',
                    required: true,
                    filterable: true,
                    endpoint: [ Vue.prototype.$ipCustomer, 'customer-group', 'list-options-planning' ],
                    conditions: [
                        {
                            AndOr: "AND",
                            column: "id_product",
                            operator: "IN",
                            value: null, // pegar dinâmico
                        },
                    ]
                },
            ]
        }
    }
}
</script>

<style scoped>
</style>